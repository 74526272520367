@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.navbar {
  width: 100%;
  background-color: transparent;
  @media (max-width: $sm) {
    padding: 20px 10px;
  }
  @media (max-width: $xxs) {
    padding: 10px;
  }
  .navDiv {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    .titleDiv {
      display: flex;
      flex-direction: column;
      font-size: 26px;
      letter-spacing: 1.5px;
      font-weight: 600;
      line-height: 20px;
      color: white;
      .salogn {
        font-size: 13px;
      }
    }
    .menuWrapper {
      display: flex;
      align-items: center;
      @media (max-width: $sm) {
        display: none;
      }
      li {
        display: flex;
        align-items: center;
        margin: 0 10px;
        @media (max-width: 310px) {
          margin: 0 8px;
        }
        a {
          text-decoration: none;
          color: #8d8699;
          font-size: 14px;
          &:hover {
            color: #8065ac;
          }
        }
      }
    }
  }
}

.subtitle {
  color: #8d8699;
  font-size: 14px;
  padding: 10px 0;
}

.submenu {
  display: flex;
  align-items: center;
  padding: 10px 0;
  @media (max-width: $sm) {
    display: none;
  }
  li {
    display: flex;
    align-items: center;
    margin: 0 10px;
    @media (max-width: 310px) {
      margin: 0 8px;
    }
    a {
      text-decoration: none;
      color: #8d8699;
      font-size: 14px;
      &:hover {
        color: #8065ac;
      }
    }
  }
}
