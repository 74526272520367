.Wrapper {
  padding: 1px;
  background-color: #ffffff40;
  width: 100%;
  margin: auto;
  clip-path: polygon(0 10%,
      10% 0,
      90% 0,
      100% 10%,
      100% 90%,
      90% 100%,
      10% 100%,
      0% 90%,
      0% 10%);

  &:hover {
    cursor: pointer;
    background-color: #1db3ae6d;

    .btnWrapper {
      border-top: 1px solid #1db3ae6d !important;
    }
  }

  .cardWrapper {
    position: relative;
    overflow: hidden;
    padding: 0;
    width: 100%;
    background: #340d31;
    clip-path: polygon(0 10%,
        10% 0,
        90% 0,
        100% 10%,
        100% 90%,
        90% 100%,
        10% 100%,
        0% 90%,
        0% 10%);

    .imgWrapper {
      margin: 20px 20px 0px 20px;
      background-color: white;
      border-radius: 10px;
      text-align: center;
      width: fit-content;
      margin: 20px auto;
    }

    .contentWrapper {
      padding: 10px 20px;

      .title {
        text-align: left;
        color: white;
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
      }

      .statusWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          font-size: 12px;
          color: gray;
          font-weight: 300;
        }

        .value {
          color: white;
          font-size: 13px;
        }
      }
    }

    .btnWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #ffffff40;

      .btnUnstake {
        background-color: transparent;
        padding: 22px;
        color: rgba(255, 255, 255, 0.76);
        min-width: 100%;

        &:hover {
          background-color: #b31d1d6d;
          border-radius: 0px;
        }
      }

      .btnCollect {
        background-color: transparent;
        padding: 22px;
        border-radius: 0px;
        color: #1db3af;
        min-width: 100%;

        &:hover {
          background-color: #1db3ae6d;
          border-radius: 0px;
        }
      }
    }
  }
}