@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box !important;
  list-style: none;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(27, 17, 41);
  background: linear-gradient(
    346deg,
    rgba(27, 17, 41, 1) 0%,
    rgba(57, 8, 97, 1) 66%,
    rgba(46, 31, 70, 0.8519782913165266) 100%
  );
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #2e1f46d9;
  border: -0 solid #2e1f46d9;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #2e1f46d9;
}
