@import "./_breakpoints.scss";
//form Grid

@mixin gridTwo($gap_y: 0, $gap_x: 0) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridThree($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridFour($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;

  @media (max-width: $lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}
