@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.layoutWrapper {
  width: 100%;
  position: relative;

  .navbar {
    width: 100%;
    background-color: transparent;

    .navDiv {
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: justify;
      justify-content: space-between;
      padding: 10px 0;

      .leftWrapper {
        display: flex;
        align-items: center;

        .titleDiv {
          .logo {
            width: 150px;
            height: 80px;
          }
        }

        .menuWrapper {
          display: flex;
          align-items: center;
          margin: 0 30px;

          @media (max-width: $sm) {
            display: none !important;
          }

          li {
            display: flex;
            align-items: center;
            margin: 0 10px;
            color: #8d8699;
            font-size: 17px;

            &:hover {
              color: #8065ac;
            }

            @media (max-width: 310px) {
              margin: 0 8px;
            }


          }
        }
      }

      .rightWrapper {
        .btnNav {
          max-width: 210px;
          border-radius: 30px;
          background-color: white;
          color: black;

          &:hover {
            background-color: rgba(255, 255, 255, 0.864);
          }
        }
      }
    }
  }

  main {
    min-height: calc(100vh - 188px);

    .mainWrapper {
      padding-top: 30px;
      padding-bottom: 30px;

      .headerWrapper {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        @media (max-width: $sm) {
          flex-direction: column;
        }

        .label {
          color: rgb(247, 247, 247);
          font-size: 38px;
        }

        .listWrapper {
          display: flex;
          align-items: center;
          padding: 6px 8px;
          border: 1px solid #ffffff40;
          border-radius: 30px;

          @media (max-width: $sm) {
            width: 100%;
            overflow: auto;
            padding: 7px 8px;
            white-space: nowrap;

            &::-webkit-scrollbar {
              display: none;
            }

            li {
              display: inline-block;
              text-align: center;
            }
          }

          li {
            padding: 8px 10px;
            margin: 0 3px;
            font-weight: 500;
            color: rgb(223, 187, 255);
            font-size: 14px;

            &:hover {
              cursor: pointer;
              background-color: #be8efe;
              color: #340d319b;
              border-radius: 30px;
            }
          }
        }
      }

      .mintWrapper {
        width: 100%;
        padding: 10px;

        @media (max-width: $sm) {
          width: 100%;
          padding: 10px 0;
        }

        .cardWrapper {
          border-radius: 20px;
          min-height: 80px;
          background-color: #340d31;
          border: 1px solid #64626398;
          padding: 0;

          .statusWrapper {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ffffff40;

            @media (max-width: $sm) {
              width: 100%;
              overflow: auto;
              white-space: nowrap;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .infoWrapper {
              display: flex;
              flex-direction: column;
              justify-items: center;
              padding: 20px;
              width: 100%;
              border-right: 1px solid #ffffff40;

              &:last-child {
                border: none;
              }

              .infoTitle {
                color: rgba(223, 187, 255, 0.605);
                font-size: 12px;
              }

              .infoData {
                color: white;
                font-size: 14px;
                font-weight: 500;
                margin: auto;
                white-space: normal;
                height: fit-content;
              }
            }
          }

          .nftContent {
            padding: 15px 20px;

            .btnAdd {
              background-color: transparent;
              color: #1db3aee8;
              padding: 0;
              margin-bottom: 8px;

              &:hover {
                color: #1db3aead;
              }
            }

            .nftcardWrapper {
              @include gridFour(15, 15);
            }
          }
        }
      }
    }
  }
}

.active {
  cursor: pointer;
  background-color: #be8efe;
  color: #340d319b !important;
  border-radius: 30px;
}

.done {
  background-color: red !important;
}

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 180vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #000102;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: rgb(145, 27, 27);
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// @-webkit-keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @-moz-keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @keyframes AnimationName {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }

// .nftapp {
//   background: radial-gradient(circle at 50% 50%, #5e99ac, #508aa3, #35678f, #1f457a, #173771);
// }

// @-webkit-keyframes AnimationName {
//   0%{background-position:0% 50%}
//   50%{background-position:100% 50%}
//   100%{background-position:0% 50%}
// }
// @-moz-keyframes AnimationName {
//   0%{background-position:0% 50%}
//   50%{background-position:100% 50%}
//   100%{background-position:0% 50%}
// }
// @keyframes AnimationName {
//   0%{background-position:0% 50%}
//   50%{background-position:100% 50%}
//   100%{background-position:0% 50%}
// }

// .connectbutton {
//   background: #00000010;
//   box-shadow: 0px 1px 10px #17b2ff;
//   color: #ffffff;
//   border-radius: 14px;
//   padding: 5px 12px;
//   font-size: large;
//   font-weight: 200;
// }

// .navbarfont {
//   font-family: "SF Pro Display";
//   font-weight: 300;
// }

// .nftminter {
//     background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
//     font-family: "SF Pro Display";
//     font-weight: 300;
//     color: white;
//     box-shadow: 0 0 1px 1px #17b2ff;
//     -webkit-border-radius: 20px !important;
//     -moz-border-radius: 20px !important;
//     border-radius: 20px !important;
//     -webkit-border: 20px !important;
//     -moz-border: 20px !important;
//     border: 20px !important;
// }

// .nftstaker {
//   background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
//   font-family: "SF Pro Display";
//   font-weight: 300;
//   color: white;
//   display: flex;
//   justify-content: center;
//   box-shadow: 0 0 1px 1px #17b2ff;
//   -webkit-border-radius: 20px !important;
//   -moz-border-radius: 20px !important;
//   border-radius: 20px !important;
//   -webkit-border: 20px !important;
//   -moz-border: 20px !important;
//   border: 20px !important;
// }

// .stakingrewards {
//     font-family: "SF Pro Display";
//     font-weight: 300;
//     box-shadow: 1px 1px 15px #ffffff;
//     background: radial-gradient(circle at 50% 50%, #000000, #050604, #0a0c08, #0f130b, #14190f, #191f13, #1e2517, #232b1b, #28321e, #2d3822, #323e26);
// }

// .modal-style1 {
//   background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
//   font-family: "SF Pro Display";
//   font-weight: 300;
//   color: white;
//   box-shadow: 0 0 1px 1px #17b2ff;
//   -webkit-border-radius: 20px !important;
//   -moz-border-radius: 20px !important;
//   border-radius: 20px !important;
//   -webkit-border: 20px !important;
//   -moz-border: 20px !important;
//   border: 20px !important;
// }

// .container-style {
//   display: grid;
//   grid-template-columns: 400px 1fr;
//   grid-column-gap: 20px;
//   grid-row-gap: 20px;
//   justify-items: stretch;
//   align-items: stretch
// }

// @keyframes glow2 {
//   from {
//     box-shadow: 0 0 4px -1px #f4dc76;
//   }
//   to {
//     box-shadow: 0 0 10px 7px #ffb300;
//   }
// }

// .stakegoldeffect {
//     font-family: "SF Pro Display";
//     font-weight: 300;
//     animation: glow2 3s infinite alternate;
//     background: linear-gradient(
//       to bottom,
//       rgb(1, 53, 130),
//       rgba(255, 0, 0, 0)
//     )
// }

// .errorsearch {
//   background: black;
// }

// .nft-card {
//   background-image: linear-gradient(125.83deg, rgb(30, 42, 34) 0%, rgb(17, 3, 141) 80.09%);
//   font-family: "SF Pro Display";
//   color: white;
//   box-shadow: 1px 1px 15px #000000;
// }

// .navbarglow {
//   box-shadow: 0px 8px 15px #17b2ff;
// }
// .farm {
//   background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(90,92,106,1) 0%, rgba(32,45,58,1) 81.3% );
//   font-family: "SF Pro Display";
//   font-weight: 300;
//   color: rgb(255, 255, 255);
//   box-shadow: 0 0 1px 1px #ffffff;
//     -webkit-border-radius: 20px !important;
//     -moz-border-radius: 20px !important;
//     border-radius: 20px !important;
//     -webkit-border: 20px !important;
//     -moz-border: 20px !important;
//     border: 20px !important;
// }
// .farmcard {
// color: rgb(255, 255, 255);
// font-family: "SF Pro Display";
// font-weight: 300;
// background-image: linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
// }
// .n2dtitlestyle {
//   color: #ffffff;
//   font-family: "SF Pro Display";
//   font-weight: 300;
// }

// .nftportal {
//   background: #161616;
// }

// .stakedcard {
//   background: linear-gradient(270deg, #0c0c0c, #f21d1d);
//   background-size: 400% 400%;
//     -webkit-animation: AnimationStake 14s ease infinite;
//     -moz-animation: AnimationStake 14s ease infinite;
//     animation: AnimationStake 14s ease infinite;
//     font-family: "SF Pro Display";
//     color: rgb(255, 255, 255);
//     box-shadow: 1px 1px 11px #FFD700;
//   }

// @-webkit-keyframes AnimationStake {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @-moz-keyframes AnimationStake {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }
// @keyframes AnimationStake {
//     0%{background-position:0% 50%}
//     50%{background-position:100% 50%}
//     100%{background-position:0% 50%}
// }